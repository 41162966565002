import React from "react";
// import SliderImages from "./SliderImages";
// import Products from "./Products"
// import Delivery from "./Delivery";
// import Offer from "./Offer";
import "../assets/css/all.min.css"
import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/css/owl.carousel.css"
import "../assets/css/magnific-popup.css"
import "../assets/css/animate.css"
import "../assets/css/meanmenu.min.css"
import "../assets/css/main.css"
import "../assets/css/responsive.css"
// import Frontlook from "./Frontlook";
// import GetinTouch from "./GetinTouch";
// import Fresh from "./Fresh";
// import SliderImages from "./SliderImage";
// import AddToCart from "./AddToCart";
import MiniMenu from "./MiniMenu";
import Review from "./Review";
// import Slider from "./Slideshow/Slider";
import Menucart from "./PremiumLook/Menucart"
import Subscribe from "./Subscribe";
import Blog from "./Blog";
import Images from "./Images";
import HomeFooter from "./HomeFooter";
import SliderImages from "./SliderImage";

function Homepage() {
    return(
        <>
        {/* <Slider /> */}
        <SliderImages />
        <Menucart />
        <Subscribe />
        <Review />
        <MiniMenu />
        <Blog />  
        <Images />
        <HomeFooter />
        </>
    )
}
export default Homepage;