import React from "react";
import Album from "../GalleryPage/Album";
// import Slider from "../example/Slider";

function Gallery() {
    return(
        <>
        <Album />
        {/* <Slider /> */}
        
        </>
    )
}
export default Gallery;