import React from "react";
import coldcoffee from "../../assets/img/iced-coffee-with-instant-coffee-5.jpg"
import vanila from "../../assets/img/shakes/classic-vanilla.jpg"
import oreo from "../../assets/img/shakes/Oreo-Milkshake.jpg"
import CH from "../../assets/img/shakes/ChocolateHazelnut.jpg"
import Sshakes from "../../assets/img/shakes/Sshakes.jpg"
import green from "../../assets/img/kiwi-milk-shake-3.jpg"
import green1 from "../../assets/img/Forest-fruits-banana-smoothie.jpg"
import Butterscotch from "../../assets/img/Butterscotch-milkshake-order-online.jpg"
import "../PremiumLook/Menucart.css"
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

function MenuCart(){
	const handleClick = () => {
		// Handle the desired action here
		// For example, show a message
		console.log('Link clicked');
		
	  };
	useEffect(() => {
        Aos.init();
      }, [])
	  
    return(
        <>
		{/* <br/>
		<br/> */}
        <section id="center_1" className="clearfix w-100">
 <div className="container mt-4" >
  <div className="row" >
   <div className="center_1 clearfix mt-0 mb-2 mb-md-0">
	{/* <div className="cart-title">
    <div  data-aos="fade-up" className="center_1_top clearfix">
	 <h4><i className="fa fa-heart" style={{color:"#f79500"}}></i></h4>
	 <h2>Smoothies & Juices</h2>
	 <h5>Try Our New Collection</h5>	
	</div></div> */}
	 {/* <div className="center_5_top mw-100 position-relative start-10 align-items-center justify-content-center" style={{border:"1px solid red"}}>
	    <p><i className="fa fa-heart" style={{color:"#f79500"}}></i></p>
	    <h2>Smoothies & Juices</h2>
		<h5>Try Our New Collection</h5>
	   </div>  */}
	   	<div className="d-flex align-items-center justify-content-center w-100">
	   <div className="center_5_top mw-100 position-relative start-10 align-items-center justify-content-center" >
	    <p><i className="fa fa-heart" style={{color:"#f79500"}}></i></p>
	    <h2>Smoothies & Juices</h2>
		<h5>Try Our New Collection</h5>
	   </div>
	    </div>
	<div className="add-cart" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1000">
	<div className="center_1_middle clearfix w-100">
	 <div className="col-sm-3 w-100 center_1_middle_left_1 clearfix">
	  <div className="center_1_middle_left_1_inner w-100 ">
	   <div className="ih-item square effect6 from_top_and_bottom"><a onClick={handleClick}> 
                      <div className="imgg"><img src={coldcoffee} alt="img" /></div>
                      <div className="info w-80">
                        <h3>add to cart</h3>
                      </div></a></div>
	  </div>
	  <div className="center_1_middle_left_2_inner">
	  <h3><a onClick={handleClick}>Cold Coffee</a></h3>
	  <h5><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></h5>
	  <h6>₹ 85.00</h6>
	 </div>
	 </div>
	 <div className="col-sm-3 w-100 center_1_middle_left_1 clearfix">
	  <div className="center_1_middle_left_1_inner">
	   <div className="ih-item square effect6 from_top_and_bottom"><a onClick={handleClick}>
                      <div className="imgg"><img src={oreo} alt="img" /></div>
                      <div className="info w-100">
                        <h3>add to cart</h3>
                      </div></a></div>
	  </div>
	  <div className="center_1_middle_left_2_inner">
	  <h3><a onClick={handleClick}>Oreo Milkshake</a></h3>
	  <h5><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></h5>
	  <h6>₹ 100.00</h6>
	 </div>
	 </div>
	 <div className="col-sm-3 w-100 center_1_middle_left_1 clearfix">
	  <div className="center_1_middle_left_1_inner">
	   <div className="ih-item square effect6 from_top_and_bottom"><a onClick={handleClick}>
                      <div className="imgg"><img src={vanila} alt="img" /></div>
                      <div className="info w-100">
                        <h3>add to cart</h3>
                      </div></a></div>
	  </div>
	  <div className="center_1_middle_left_2_inner">
	  <h3><a onClick={handleClick}>Vanilla Milkshake</a></h3>
	  <h5><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></h5>
	  <h6>₹ 120.00</h6>
	 </div>
	 </div>
	 <div className="col-sm-3 w-100 center_1_middle_left_1 clearfix">
	  <div className="center_1_middle_left_1_inner">
	   <div className="ih-item square effect6 from_top_and_bottom"><a onClick={handleClick}>
                      <div className="imgg"><img src={green} alt="img" /></div>
                      <div className="info w-100">
                        <h3>add to cart </h3>
                      </div></a></div>
	  </div>
	  <div className="center_1_middle_left_2_inner">
	  <h3><a onClick={handleClick}>Kiwi Milkshake</a></h3>
	  <h5><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></h5>
	  <h6>₹150.00</h6>
	 </div>
	 </div>
	</div>
	</div>
	<div className="add-cart" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1000">
	<div className="center_1_middle clearfix">
	 <div className="col-sm-3 w-100 center_1_middle_left_1 clearfix">
	  <div className="center_1_middle_left_1_inner">
	   <div className="ih-item square effect6 from_top_and_bottom"><a onClick={handleClick}>
                      <div className="imgg"><img src={Butterscotch} alt="img" /></div>
                      <div className="info w-100">
                        <h3>add to cart</h3>
                      </div></a></div>
	  </div>
	  <div className="center_1_middle_left_2_inner">
	  <h3><a onClick={handleClick}>Butterscotch</a></h3>
	  <h5><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></h5>
	  <h6>₹ 140.00</h6>
	 </div>
	 </div>
	 <div className="col-sm-3 w-100 center_1_middle_left_1 clearfix">
	  <div className="center_1_middle_left_1_inner">
	   <div className="ih-item square effect6 from_top_and_bottom"><a onClick={handleClick}>
                      <div className="imgg"><img src={Sshakes} alt="img" /></div>
                      <div className="info w-100">
                        <h3>add to cart</h3>
                      </div></a></div>
	  </div>
	  <div className="center_1_middle_left_2_inner">
	  <h3><a onClick={handleClick}>Strawberry</a></h3>
	  <h5><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></h5>
	  <h6>₹ 180.00</h6>
	 </div>
	 </div>
	 <div className="col-sm-3 w-100 center_1_middle_left_1 clearfix">
	  <div className="center_1_middle_left_1_inner">
	   <div className="ih-item square effect6 from_top_and_bottom"><a onClick={handleClick}>
                      <div className="imgg"><img src={CH} alt="img" /></div>
                      <div className="info w-100">
                        <h3>add to cart</h3>
                      </div></a></div>
	  </div>
	  <div className="center_1_middle_left_2_inner">
	  <h3><a onClick={handleClick}>Choco milkshake</a></h3>
	  <h5><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></h5>
	  <h6>₹ 100.00</h6>
	 </div>
	 </div>
	 <div className="col-sm-3 w-100 center_1_middle_left_1 clearfix">
	  <div className="center_1_middle_left_1_inner">
	   <div className="ih-item square effect6 from_top_and_bottom"><a onClick={handleClick}>
                      <div className="imgg"><img src={green1} alt="img" /></div>
                      <div className="info w-100">
                        <h3>add to cart</h3>
                      </div></a></div>
	  </div>
	  <div className="center_1_middle_left_2_inner">
	  <h3><a onClick={handleClick}>Fruit Milkshake</a></h3>
	  <h5><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i></h5>
	  <h6>₹ 170.00</h6>
	 </div>
	 </div>
	</div>
	</div>
   </div>
  </div>
 </div>
</section>
        </>
    )
}
export default MenuCart