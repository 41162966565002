import React from 'react'
import vegBurger from "../assets/img/Bun/veg burger.jpg"
import chknBurger from "../assets/img/Bun/chknBurger.jpg"
import chknCheese from "../assets/img/Bun/cheesechkn.jpg"
import vegcheese from "../assets/img/Bun/cheeseVeg.jpg"


function BunTheory() {
  return (
    <>
    
    <section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={vegBurger} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 60.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veg Burger</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={chknBurger} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 80.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Burger</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={vegcheese} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken cheese Burger</a></h4>
	 </div>
	</div>
  
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={chknCheese} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veg cheese Burger</a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
    
    </>
  )
}

export default BunTheory