import React from 'react'
import chaitheory from "../assets/img/chai-theory.jpeg"
import about from "../assets/img/about-us2.jpeg"
import group from "../assets/img/group.jpeg"
// import cup from "../assets/img/cup.png"
// import

function About() {
  return (
    <>

{/* 17em 2em 40em / 2.5em 6em; */}
<div className='bg-about'>
    <div className="container w-100">
  <div className="row mt-5">
    <div className="col-sm-6 w-100 mr-5 mt-5" data-aos="fade-right" data-aos-duration="1500">
      <img className="w-100 shadow-lg rounded-4" src={chaitheory} alt='chaitheory'/>
    </div>
    <div className="col-sm-5 w-100  m-auto">
      <div className="w3-container w3-center inner-container w3-animate-top w-100">
          <p className='text-center w-100 h2 font-weight-bold'>About Chai Theory </p>
          <p className="text w-100 h4 lh-base p-2 text-justify">Chai Theory is one of the subsidiaries of the Sristar Group of Companies in Bangalore 
		  that serves various types of flavored teas to all tea lovers and discerning customers. Considering the vast demand for tea and the mindset of the Indian people, 
		  our skilled professionals prepare tea in a unique style with different blends of ingredients to give our customers a unique taste with uncompromised hygiene and quality, 
		  ensuring you experience a homely atmosphere.</p>
       
        </div>
    </div>
	

	<div className='col-md-12 p-2 text-center'>
	<br/>
    <div className="col-sm-6 w-100 mt-5">
      <div className="w3-container w3-center inner-container w3-animate-top w-100">
          <p className='text-center w-100 h2 font-weight-bold'>What are our services? </p>
          <p className="text w-100 h4 lh-base p-2 text-justify">Chai Theory is one of the renowned tea cafes in Bangalore that offers a diverse array of hot and cold beverages, catering to various tastes and preferences. We pride ourselves on curating a selection of snacks infused with a delightful desi twist, ensuring a unique and flavorful experience. While we offer a range of beverages, we particularly emphasize the art of tea-making, providing an extensive tea menu that caters to tea enthusiasts. Whether the customers crave for a soothing cup of chai or desire a refreshing cold drink, our service guarantees a rich and satisfying culinary journey with a touch of Indian essence.
</p>
       
        </div>
    </div>
	<div className="col-sm-6 w-100 mt-5" data-aos="fade-left" data-aos-duration="1500">
		<br/>
		{/* <br/> */}
      <img className="w-100 shadow-lg rounded-4" src={group} alt='chaitheory'/>
    </div>
	</div>



	<div className='col-md-12'>
	<br/>
	<div className="col-sm-6 w-100 mt-4" data-aos="flip-left" data-aos-duration="1500">
      <img className="shadow-lg rounded-3 d-flex" src={about} alt='chaitheory' style={{width:"280px", height:"635px", marginLeft:"auto", marginRight:"auto"}}/><br/>
	  <br/></div>
    <div className="col-sm-6 w-100 mt-5">
      <div className="w3-container w3-center inner-container w3-animate-top w-100">
          <p className='text-center w-100 h2 font-weight-bold'>Why Us? </p>
          <p className="text w-100 h4 lh-base p-2 text-justify">Though tea is the lifeline for many people in India, they think that preparing tea is a simple task. However, only a few know that natural ingredients like ginger, elaichi, lemon, masala, pepper, cinnamon, coconut, hazelnut, ginger jaggery, and a sugarless version will have different effects and a unique experience. Each cup is made with freshly brewed tea, and no tea is recycled for additional use. We at Chai Theory take pride in our excellent work, preparing different varieties of tea with other snacks and drinks that act as a mind booster to relieve stress, soothe your throat, and clear your headache in no time.   We give utmost importance to the following:
		  <br/>
		  <br/>
		  <span><i class="fa fa-check-square"></i></span> High-quality, fresh, and natural medicinal herbs.<br/>
		  <span><i class="fa fa-check-square-o"></i></span> Customer satisfaction.<br/>
		  <span><i class="fa fa-check-square-o"></i></span> Hot & fresh drinks.<br/>
		  <span><i class="fa fa-check-square-o"></i></span> High quality and hygiene.<br/>
		  <span><i class="fa fa-check-square-o"></i></span> 0% preservatives<br/>
		  <span><i class="fa fa-check-square-o"></i></span> 100% Hygiene.<br/>
</p>
       
        </div>
    </div>
	
	</div>
	
</div>
</div>
</div>

    </>
  )
}

export default About