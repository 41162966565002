import React from 'react'
import bread from "../assets/img/bread/bread-butter-and-jam.jpg"
import bjc from "../assets/img/bread/peanut-butter-jelly.jpg"
import Vsandwich from "../assets/img/bread/Veg-Sandwich.jpg"
import Csandwich from "../assets/img/bread/chkn-sandwich.jpg"
import cornsandwich from "../assets/img/bread/CornSandwichCheese.jpg"
import VCsandwich from "../assets/img/bread/VegSandwichCheese.jpg"
import psandwich from "../assets/img/bread/PaneerSandwichCheese.jpg"
import CCsandwich from "../assets/img/bread/Chicken-Sandwich.jpg"
import choclate from "../assets/img/bread/ChocolateSandwich.jpg"


function BreadTheory() {
  return (
    <>

<section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={bread} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 79.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Bread Jam / Butter</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={bjc} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 89.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Bread Jam Choco</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">  
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={Vsandwich} alt="img"/></div>
	  <div className="info">
      <h3>Regular:"₹ 79.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veg Sandwich</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={bread} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 79.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Bread Jam / Butter</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={Csandwich} alt="img"/></div>
	  <div className="info">
      <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Sandwich</a></h4>
	 </div>
	</div>
 
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={choclate} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chocolate Sandwich</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={VCsandwich} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 89.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veg Sandwich With Cheese</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={psandwich} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Paneer Sandwich With Cheese</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={CCsandwich} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 109.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Sandwich With Cheese</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={cornsandwich} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 89.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Corn & Peas Sandwich With Cheese</a></h4>
	 </div>
	</div>
    
   </div>
  </div>
 </div>
</section>

    </>
  )
}

export default BreadTheory