import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Navpages/Home"
import About from "../Navpages/About"
import Navbar from "./Navbar";
import Menu from "../Navpages/Menu";
import Contact from "../Navpages/Contact"
import Gallery from "../Navpages/Gallery"
import Franchise from "../Navpages/Franchise"
import Careers from "../Navpages/Careers";
import ApplyNow from "../Components/ApplyNow";
import ApplyHere from "../Components/ApplyHere";
import SpecialTheory from "../Menus/SpecialTheory";
import DarkTheory from "../Menus/DarkTheory";
import Coffee from "../Menus/Coffee";
import Shakes from "../Menus/Shakes";
import Immunity from "../Menus/Immunity";
import Mojitos from "../Menus/Mojitos";
import IcedTheory from "../Menus/IcedTheory";
import Scoops from "../Menus/Scoops";
import Maggie from "../Menus/Maggie";
import Munchies from "../Menus/Munchies";
import BakePoint from "../Menus/BakePoint";
import Crunchies from "../Menus/Crunchies";
import BreadTheory from "../Menus/BreadTheory";
import BunTheory from "../Menus/BunTheory";
import Wraps from "../Menus/Wraps";
import AndeKaFunda from "../Menus/AndeKaFunda";
import Rolls from "../Menus/Rolls";
// import Navbars from "./Navbars";

function Router() {
    
    return (
        <>
            {/* <BrowserRouter>
            <Navbar />
                <Routes>
                <Route path="/" exact element={<Home />} />
                    <Route path="/home"  element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact /> } />
                    <Route path="/menu" element={<Menu />} />
                    {/* <Route path="/franchise" element={<Franchise />} /> */}
                    {/* <Route path="/career" element={<Career />} /> */}
                    {/* <Route path="/media" element={<Media />} /> */}                   
                   {/* <Route path="/privacy-policy" element={<Privacypolicy />} /> */}
                   {/* <Route path="/terms-of-services" element={<Termsofservices />} /> */}
                   {/* <Route path="/acknowledgement" element={<Acknowledgement />} /> */}
                   {/* <Route path="/Refund-policy" element={<Refundpolicy />} />
                   <Route path="/Our-story" element={<Ourstory />} />
                   <Route path="/Our-vision" element={<Ourvision />} />
                   <Route path="/community" element={<Community />} /> */}


            {/* </Routes> */}
           
            {/* </BrowserRouter> */}
            <BrowserRouter>
       <Navbar />

        <div className="pages">
          <Routes>
            <Route path="/" exact element={<Home /> } />
            <Route path="/home" exact element={<Home /> } />
            <Route path="/about" exact element={<About />} />
            <Route path="/contact" exact element={<Contact />} />            
            <Route path="/menu" exact element={<Menu />} />
            <Route path="/careers" exact element={<Careers />}/>
            <Route path="/gallery" exact element={<Gallery />} />
            <Route path="/franchise" exact element={<Franchise />} />
            <Route path="/apply" exact element={<ApplyNow />} />
            <Route path="/applyhere" exact element={<ApplyHere />} />
            
            <Route path="/mojitos" exact element={<Mojitos /> }/>
            <Route path="/scoops" exact element={<Scoops /> }/>
            <Route path="/wraps" exact element={<Wraps />} />
            <Route path="/iced-theory" exact element={<IcedTheory /> }/>
            <Route path="/milktheory" exact element={<SpecialTheory />} />
            <Route path="/darktheory" exact element={<DarkTheory />} />
            <Route path="/coffee" exact element={<Coffee />} />
            <Route path="/shakes" exact element={<Shakes />} />
            <Route path="/munchies" exact element={<Munchies /> }/>
            <Route path="/bake-point" exact element={<BakePoint /> }/>
            <Route path="/crunchies" exact element={<Crunchies /> }/>
            <Route path="/maggie" exact element={<Maggie /> } />
            <Route path="/breadtheory" exact element={<BreadTheory /> } />
            <Route path="/buntheory" exact element={<BunTheory /> } />
            <Route path="/rolls" exact element={<Rolls /> } />
            <Route path="/andekafunda" exact element={<AndeKaFunda /> } />
            <Route path="/immunity-booster" exact element={<Immunity />} />
          </Routes>
        </div>
      </BrowserRouter>
        </>
    );
}
export default Router;