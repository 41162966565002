import React from "react";
import offer from "../assets/img/company-logos/franchisee.jpg"
import man from "../assets/img/company-logos/man.jpg"
import chai from "../assets/img/company-logos/ekdum.jpg"
import first from '../assets/img/company-logos/1st.jpg'
import aug from "../assets/img/company-logos/15.jpg"
import ele from "../assets/img/company-logos/ele.jpg"

function Images() {
    return(
        <>
        <section id="center_6" className="clearfix">
   <div className="center_6 clearfix">
	   <div className="center_6_middle clearfix">
	    <div className="col-sm-2">
		 <div className="center_6_middle_left_inner_1 clearfix w-100">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={first} alt="offer"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		</div>
		<div className="col-sm-2 center_6_middle_left clearfix">
		 <div className="center_6_middle_left_inner_1 clearfix">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={man} alt="offer"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		</div>
		<div className="col-sm-2 center_6_middle_left clearfix">
		 <div className="center_6_middle_left_inner_1 clearfix">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={chai} alt="offer"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		</div>
		<div className="col-sm-2 center_6_middle_left clearfix">
		 <div className="center_6_middle_left_inner_1 clearfix">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={offer} alt="offer"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		</div>
		<div className="col-sm-2 center_6_middle_left clearfix">
		 <div className="center_6_middle_left_inner_1 clearfix">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={aug} alt="offer"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		</div>
		<div className="col-sm-2 center_6_middle_left clearfix">
		 <div className="center_6_middle_left_inner_1 clearfix">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={ele} alt="offer"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		</div>
	   </div>
   </div>
 </section>
        </>
    )
}
export default Images;