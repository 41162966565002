import React from 'react'
import salt from '../assets/img/BakePoint/salt-cookies.jpg'
import donut from '../assets/img/BakePoint/Donuts.jpg'
import banana from '../assets/img/BakePoint/banana-cake.jpg'
import brown from '../assets/img/BakePoint/brown-cake.jpg'
import chocochip from '../assets/img/BakePoint/chocolate-chip.jpg'
import sweet from '../assets/img/BakePoint/sweet_biscuits.jpg'

function BakePoint() {
  return (
    <div>

<section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={salt} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 6.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Salt Biscuit</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={sweet} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 6.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Sweet Biscuit</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={banana} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 59.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Banana Cake</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={donut} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 59.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chocolate Donut</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={brown} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 59.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Brownie</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={chocochip} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 65.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chocochip Muffin</a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>

    </div>
  )
}

export default BakePoint