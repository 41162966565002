import React from "react";
import FranchiseBusiness from "../Franchise/FranchiseBusiness";
import HomeFooter from "../Components/HomeFooter";

function Franchise() {
    return(
        <>
       <FranchiseBusiness />
       <HomeFooter />
        </>
    )
}
export default Franchise;