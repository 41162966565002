import React from "react";
import "./slider.css"
// import First from "../assets/img/15833.jpg";
import chaibanner from "../assets/img/tea slider.jpg"
import teaShop from "../assets/img/teashop.png"
import cup from "../assets/img/cup.png"

function SliderImages() {
    return(
        <>
<div id="carouselExampleControls" className="carousel slide" data-ride="carousel" style={{top:"-20px"}}>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="https://todaytea.com/images/banner-products.jpg" className="d-block w-100" alt="..." style={{height:"350px"}}/>
    </div>
    <div className="carousel-item">
      <img src={chaibanner} className="d-block w-100" alt="..." style={{height:"350px"}}/>
      {/* <div class="carousel-caption d-none d-md-block">
        <img src={cup} className="cup-banner-img"/>
      </div> */}
    </div>
    <div className="carousel-item">
      <img src="https://www.teaemporium.ie/pub/media/magiccart/magicslider/b/a/banner-img1.jpg" className="d-block w-100" alt="..." style={{height:"350px"}} />
    </div>
<div className="carousel-item">
      <img src="https://as1.ftcdn.net/v2/jpg/03/50/80/74/1000_F_350807464_paCHiBvyeKtA5cosCFa8qRsbqmE6Aeyq.jpg" className="d-block w-100" alt="..." style={{height:"350px"}}/>
    </div>
    <div className="carousel-item">
      <img src="https://cdn.mantansupermart.com/assets/uploads/category/compress/952_x_250-11.jpg" className="d-block w-100" alt="..." style={{height:"350px"}}/>
    </div>
    <div className="carousel-item">
      <img src="https://i.pinimg.com/originals/8d/72/04/8d7204209fd2f8dc1aeec9cd3c4968b0.jpg" className="d-block w-100" alt="..." style={{height:"350px"}}/>
    </div>
 <div className="carousel-item">
      <img src={teaShop} className="d-block w-100" alt="..." style={{height:"350px"}}/>
    </div>
     <div className="carousel-item">
      <img src="https://i.pinimg.com/1200x/60/2f/79/602f796e07a8fd3c8173c9602fcb8e6a.jpg" className="d-block w-100" alt="..." style={{height:"350px"}}/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
        </>
    )
}
export default SliderImages