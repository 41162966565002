import React from 'react'
import hazelnut from "../assets/img/icedtheory/Hazelnut.jpg"
import lemoncade from "../assets/img/icedtheory/Lemonade.jpg"
import lemonginger from "../assets/img/icedtheory/lemon-ginger.jpg"
import icemint from "../assets/img/icedtheory/mint.jpg"
import peach from "../assets/img/icedtheory/peach.jpg"
import spicy from "../assets/img/Mojitos/spicy-mint-lemon.jpg"

function IcedTheory() {
  return (
    <>

<section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={icemint} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Mint</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={peach} alt="img"/></div>
	  <div className="info">
      <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Peach</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={lemoncade} alt="img"/></div>
	  <div className="info">
      <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Lemoncade</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={lemonginger} alt="img"/></div>
	  <div className="info">
      <h3>Regular:"₹ 109.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Ginger Lemon</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={hazelnut} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 109.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Hazelnut</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={spicy} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Spicy Mint & Lemon</a></h4>
	 </div>
	</div>  
   </div>
  </div>
 </div>
</section>

    </>
  )
}

export default IcedTheory