import React from 'react'

function Contactus() {
  return (
    <>
   <div className='bg'>
   <section id="contact">
    <div className="container">
	  <div className="row">
	  <div className="contact clearfix w-100">
	    <div className="col-sm-12 w-100">
		   <h1 className="text-center">Contact Us</h1>
		   <div className="col-sm-4 w-100">
		    <div className="contact_1 text-center">
			  <p className="text_1"><i className="fa fa-map-marker"></i></p>
			  <h3>Address</h3>
			  <span className="text_1"><a href='https://goo.gl/maps/dHFk69DHMQf1kkq68'>
			  #280/1, Anniamma Arcade, 2nd Floor, Sampige Road, 
			  18th Cross Rd, signal, Malleshwaram, Bengaluru, Karnataka 560003</a></span>
			</div>
		   </div>
		   <div className="col-sm-4 w-100">
		    <div className="contact_1 text-center">
			  <p className="text_2"><i className="fa-solid fa-phone"></i></p>
			  <h3>Phone</h3>
			  <span className="text_1">Phone:  <a href='tel:'> +91 6366333444</a></span>
			</div>
		   </div>
		   <div className="col-sm-4 w-100">
		    <div className="contact_1 text-center">
			  <p className="text_2"><i className="fa fa-envelope"></i></p>
			  <h3>E-mail</h3>
			  <span className="text_1">E-mail: <a href="mailto:chaitheory.post@gmail.com">chaitheory.post@gmail.com</a></span>
			</div>
		   </div>
		</div>
		<div className="col-sm-12 contact_bottom clearfix w-100">
		 <div className="contact_bottom_main clearfix">
		  <div className="col-sm-6 contact_bottom_main_left clearfix w-100">
		   <div className="contact_bottom_main_left_top clearfix">
		    <h3 className="text-center font-weight-bold">Get In Connect With Our Company</h3>
		   <div className="">
		   <div className="contact_2 mb-5 pb-2 col-sm-12 w-100">
		    <input className="form-control" type="text" placeholder="Name"/>
		   </div>
		  </div>
		   <div className="">
		   <div className="contact_2 col-sm-12 w-100">
		    <input className="form-control" type="text" placeholder="Email"/>
		   </div>
		  </div>
		   <div className="">
		   <div className="contact_2 col-sm-12 w-100">
		    <input className="form-control" type="text" placeholder="Telephone"/>
		   </div>
		  </div>
		   <div className="col-sm-12 contact_3 clearfix w-100">
		    <div className="contact_3_top">
			  <textarea placeholder="Message..." className="form-control form_1"></textarea>
			   <a href="/contact">Submit</a>
			</div>
		  </div>
		   </div>
		  </div>
		  <div className="col-sm-6 contact_bottom_main_right clearfix w-100">
		   <div className="contact_bottom_main_right_top clearfix">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.0294715069617!2d77.56601697420592!3d13.033795213513448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17603d3a7421%3A0xdc9a4c20401e825f!2sChai%20Theory-New%20Bell%20Road!5e0!3m2!1sen!2sin!4v1691281245823!5m2!1sen!2sin" 
		   width="100%" height="580" style={{border:"0"}}  loading="lazy"  title="myFrame"></iframe>
		   </div>
		  </div>
		 </div>
		</div>
	  </div>
	  </div>
	</div>
   </section>
   </div>


    </>

  )
}

export default Contactus;