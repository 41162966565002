import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
import logo from "../assets/img/logo.png"
// import Search from "./Search";
function Navbar() {
    const [click, setClick] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => setClick(!click);
    const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
    return (
        <>
       
            <nav className="navbar">
                <div className="nav-container">
                    <NavLink  to="/" className="nav-logo">
                        <img src={logo} alt="logo" style={{ height: "60px", width: "120px" }} />
                    </NavLink>

                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink
                                // exact
                                to="/home"
                                // activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}   
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                // exact
                                to="/about"
                                // activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                About 
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink
                                // exact
                                to="/menu"
                                // activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Menu 
								</NavLink>
                               
                        </li>         */}


   <li className="dropdown nav-item " onClick={toggleDropdown}> 
           <button className="dropdown-button" style={{backgroundColor:"transparent"}}>Menu</button>
        {isOpen && (
          <div className="dropdown-content w-100">
            <div className=" scrollable-menu">
            <div className="col-sm-6">
            <Link to="/mojitos" className="nav-links ">Mojitos</Link>
            <Link to="/milktheory" className="nav-links ">Milk THeory</Link>
            <Link to="/darktheory" className="nav-links ">Dark Theory</Link>
            <Link to="/iced-theory" className="nav-links ">Iced Theory</Link>
            <Link to="/coffee" className="nav-links ">Coffee</Link>
            <Link to="/shakes" className="nav-links ">Shakes</Link>
            <Link to="/rolls" className="nav-links ">Rolls</Link>
            <Link to="/immunity-booster" className="nav-links ">Immunity Booster</Link>
            
            </div>
            <div className="col-sm-6">
          <Link to="/bake-point" className="nav-links ">Bake Point</Link>
          <Link to="/crunchies" className="nav-links ">Crunchies</Link>
            <Link to="/munchies" className="nav-links ">Munchies</Link>
            <Link to="/breadtheory" className="nav-links ">Bread Theory</Link>
            <Link to="/buntheory" className="nav-links ">Bun Theory</Link>
          <Link to="/wraps" className="nav-links ">Wraps</Link>
          <Link to="/andekafunda" className="nav-links ">Ande Ka Funda</Link>
          <Link to="/maggie" className="nav-links ">Maggi Mashups</Link>
          </div>
           </div>
           </div>
        )}
      </li>
    
           
                              <li className="nav-item">
                            <NavLink
                                // exact
                                to="/franchise"
                                // activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Franchise
                            </NavLink>
                        </li> 
                        <li className="nav-item">
                            <NavLink
                                // exact
                                to="/careers"
                                // activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Careers
                            </NavLink>
                        </li>      
                        <li className="nav-item">
                            <NavLink
                                // exact
                                to="/gallery"
                                // activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Gallery
                            </NavLink>
                        </li>                          
                        <li className="nav-item">
                            <NavLink
                                // exact
                                to="/contact"
                                // activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Contact
                            </NavLink>
                        </li>    		
                    </ul>
                    {/* <Search /> */}
                    {/* <div className="search">
        <div className="icon"></div>
        <div className="form">
            <form>
                <input type="text" name="" placeholder="What are you looking for ?!"/>
            </form>
        </div>
    </div> */}
    {/* <div className="box">
    <form name="search">
        <input type="text" className="input" name="txt" 
        onmouseout="this.value = ''; this.blur();"/>
    </form>
    <i className="fas fa-search"></i>

</div> */}
                    {/* <label className="label">
                    <i className="fa fa-search" aria-hidden="true" className="i-tag"></i>
                    <input placeholder="Search" type="search" name="search" value=""/></label> */}

                    <div className="nav-icon" onClick={handleClick}>
                        <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;