import React from 'react'
import blue from "../assets/img/Mojitos/bluemojito.jpg"
import strbryMojito from "../assets/img/Mojitos/strawberry-mojito.jpg"
import green from "../assets/img/Mojitos/green-mojito.jpg"
import lemon from "../assets/img/Mojitos/lemonade.jpg"
import yellow from "../assets/img/Mojitos/mango-mojito.jpg"
import mint from "../assets/img/Mojitos/mintmojito.jpg"
import spicy from "../assets/img/Mojitos/spicy-mint-lemon.jpg"

function Mojitos() {
  return (
    <>
    
    <section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={blue} alt="img"/></div>
	  <div className="info">
		<h3>₹95.00</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Blue Ice</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={strbryMojito} alt="img"/></div>
	  <div className="info">
      <h3 className='font-weight-bold'>₹95.00</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Strawberry</a></h4>
	 </div>
	</div>

    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={green} alt="img"/></div>
	  <div className="info">
      <h3>₹99.00</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Green Apple</a></h4>
	 </div>
	</div>

  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={lemon} alt="img"/></div>
	  <div className="info">
      <h3>₹95.00</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Lemonade</a></h4>
	 </div>
	</div>

  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={yellow} alt="img"/></div>
	  <div className="info">
      <h3>₹99.00</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Mango</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={mint} alt="img"/></div>
	  <div className="info">
      <h3>₹95.00</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Mint</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={spicy} alt="img"/></div>
	  <div className="info">
      <h3>₹99.00</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Spicy Mint & Lemon</a></h4>
	 </div>
	</div>
  
   </div>
  </div>
 </div>
</section>

    </>
  )
}

export default Mojitos