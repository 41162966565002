import React from 'react'
import fries from "../assets/img/Crunchies/fries.jpg"
import bigfries from "../assets/img/Crunchies/big_french_fries.jpg"
import twister from "../assets/img/Crunchies/twister.jpg"
import peritwister from "../assets/img/Crunchies/PeriPeriTwister.jpg"
import cheese from "../assets/img/Crunchies/Cheese.jpg"
import pizzafries from "../assets/img/Crunchies/pizzafries.jpg"
import spring from "../assets/img/Crunchies/Spring-Roll-Recipe.jpg"
import minisamosa from "../assets/img/Crunchies/MiniSamosas.jpeg"
import sweetcorn from "../assets/img/Crunchies/Sweet-Corn.jpg"
import kabab from "../assets/img/Crunchies/hara-bhara-kabab.jpg"
import Pocket from "../assets/img/Crunchies/Pizza-Pocket.jpg"
// import Paneerroll from "../assets/img/Crunchies/Paneer-kathi-Roll-Featured-1.jpg"
import periperifries from "../assets/img/Crunchies/periperifries.jpg"
import veggie from "../assets/img/Crunchies/veggie.jpg"
import Popcorn from "../assets/img/Crunchies/popcorn.jpg"
import Nuggets from "../assets/img/Crunchies/mcdonalds-chicken-nuggets.jpg"
import cheeseshorts from "../assets/img/Crunchies/CheeseShots.jpg"
import friedstrips from "../assets/img/Crunchies/Chicken-Fried-Strips-2.jpg"
import babycorn from "../assets/img/Crunchies/babycorn.jpg"
import fried from "../assets/img/Crunchies/fried-chicken-tenders.jpg"
import cheesefried from "../assets/img/Crunchies/eating-vegan-chili-cheese-fries.jpg"
import nonveg from "../assets/img/Crunchies/non-veg-platter.jpg"


function Crunchies() {
  return (
    <div>
<section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={twister} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 75.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Classic Twister</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={peritwister} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Peri Peri Twister</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={fries} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Classic Fries</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={cheesefried} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 109.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Barbeque Fries</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={periperifries} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 109.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Peri Peri Fries</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={cheese} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Cheese Jalapeno</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={pizzafries} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 125.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Pizza Fingers</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={spring} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 125.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Spring Roll</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={minisamosa} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 125.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Mini Samosa</a></h4>
	 </div>
	</div>
    
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={Popcorn} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Popcorn</a></h4>
	 </div>
	</div>    
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={kabab} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹135.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Hara Bhara Kabab</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={Pocket} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹145.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Pizza Pocket</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={veggie} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 179.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veg Snack Platter</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={Nuggets} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 125.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Nuggets</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={cheeseshorts} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 129.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Cheese Shots</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={friedstrips} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 135.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Fingers</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={fried} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 139.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Strips</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={babycorn} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 139.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Lime Honey Crisp Baby Corn</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={nonveg} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 199.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Non-Veg Loaded Cheese Fries</a></h4>
	 </div>
	</div>    
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={bigfries} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 169.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veg Loaded Cheese Fries</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={sweetcorn} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 129.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Lime Honey Crisp Sweet Corn</a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
    </div>
  )
}

export default Crunchies