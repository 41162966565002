import React from 'react'
import plain from "../assets/img/maggie/Plain-Maggi.jpg"
import veg from "../assets/img/maggie/Veggie-Maggi.jpg"
import peri from "../assets/img/maggie/Peri-Peri-Maggi.jpg"
import butter from "../assets/img/maggie/butter-garlic.jpeg"
import classic from "../assets/img/maggie/classic.jpeg"
import egg from "../assets/img/maggie/Egg Maggi.jpg"
import maggie from "../assets/img/maggie/schezwan-maggi.jpeg"
import creamy from "../assets/img/maggie/creemy-maggie.jpeg"
import corn from "../assets/img/maggie/Corn.jpeg"
import corns from "../assets/img/maggie/corn-creemy.png"
import paneer from "../assets/img/maggie/Crispy Paneer Maggi.jpg"
import thandoori from "../assets/img/maggie/thandoori.jpg"
import chicken from "../assets/img/maggie/chkn.jpeg"
import fire from "../assets/img/maggie/Fire Maggi.jpg"

function Maggie() {
  return (
    <>

<section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={plain} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 59.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Plain Maggi</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={veg} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 59.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veggie Maggi</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={peri} alt="img"/></div>
	  <div className="info">
      <h3>Regular:"₹ 79.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Peri Peri Maggi</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={egg} alt="img"/></div>
	  <div className="info">
      <h3>Regular:"₹ 79.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Egg Maggi</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={maggie} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 89.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Schezwan Maggi</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={butter} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 89.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Butter Garlic Maggi</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={classic} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Classic Cheese Maggi</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={creamy} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Creamy Maggi</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={corn} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Corn Maggi</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={corns} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 99.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Corn & Peas Maggi</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={paneer} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 109.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Crispy Paneer Maggi</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={thandoori} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 109.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Tandoori Maggi</a></h4>
	 </div>
	</div>
   <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={fire} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 149.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chai Theory Fire Maggi</a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
    </>
  )
}

export default Maggie