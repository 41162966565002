import './App.css';
import Router from './Utility/Router';
import "./assets/css/main.css"

function App() {
  return (
   <>
    <Router />
   </>
  );
}

export default App;
