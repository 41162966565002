import React from "react";
import About from "./About";

function AboutPage() {
    return(
        <>
        <About />
        {/* <div>
    <p>
        This is the shorter version of the content.
        <span id="more" class="collapse"> This is the longer version of the content.</span>
    </p>
    <input type="checkbox" id="read-more-toggle" class="collapse-toggle"/>
    <label for="read-more-toggle" data-toggle="collapse" data-target="#more" aria-expanded="false" aria-controls="more">
        Read more
    </label>
</div> */}       
	{/* <div className="about-section w-100">
  <div className="inner-container text-center w-60">
    <h1 className="w3-container w3-center w3-animate-top w-100">About Chai Theory </h1>
    <p className="text w-40 h4 lh-base p-2 text-justify">
	Sristar Group is a diversified group with interests in varied fields, 
	the group has some of the fastest-growing companies in the business, 
	and the group has interests in varied sectors. The dream to build and 
	establish various businesses was initiated in the year 2019. Srikanth V Nalige 
	and Manjappa Bidaragadda envisioned founding a company that is part of people’s 
	lives and is effective in solving their financial problems. Sristar Gold Company 
	was the result of this vision; the company buys gold by tendering the exact price 
	and value to the customers. Every transaction is executed with a completely 
	ethical and legitimate process that has resulted in the growth of the company’s 
	credibility. Sristar Gold Company intends to become a company that should be 
	known for its transparency, ethics, and customer-friendly. With the success of 
	this venture, it gained confidence and incorporated various businesses like 
	SriStar Gold Mall, Chai Theory, Asli Kahani, Digital Engine & SriStar Staffing.
    </p>
    
  </div>
</div> */}
{/* <section class="container my-5">
    <div class="row">
      <div class="col-lg-6">
        <img src="https://via.placeholder.com/400x300" alt="Company Image" class="img-fluid rounded"/>
      </div>
      <div class="col-lg-6">
        <h2 class="mb-4">About Our Company</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis tellus libero. Nunc et eleifend enim.</p>
        <p>Ut eu volutpat libero, non euismod lectus. Nam eget purus sit amet elit iaculis viverra at sit amet tellus.</p>
        <p>Suspendisse feugiat volutpat massa non tincidunt. Nullam tristique ultrices tortor vel gravida.</p>
        <a href="#" class="btn btn-primary">Learn More</a>
      </div>
    </div>	
  </section>
  <section class="bg-light py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h3 class="mb-4">Our History</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
        </div>
        <div class="col-lg-6">
          <h3 class="mb-4">Our Team</h3>
          <p>We have a dedicated team of professionals who are passionate...</p>
        </div>
      </div>
    </div>
  </section> */}
<br></br>
        </>
    )
}
export default AboutPage;