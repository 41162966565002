import React from "react";
import "./franchise.css"
import cup from "../assets/img/cup.png"
import FChaitheory from "../assets/img/outlet.png"
import Franchisee from "./Franchisee";
import FranchisePoints from "./FranchisePoints";

function FranchiseBusiness(){
	
    return(
        <>
        
<section id="newsletter">
<div className="container w-100">	
	<div className="col">
<div className="col-md-5" style={{overflow:"hidden"}}>
	<img src={FChaitheory} alt="franchisee" className="franchisee-img" style={{width:"100%"}}/>
</div>
	</div>
	<div className="col">
<div className="col-sm-8 col-md-6 col-xs-12 col-lg-5 w-100 Ftitle">
	<img src="https://fivestarchicken.com/home_banner/Franchise1513066642franchise-title.png" alt="franchise" className="w-100"/>	</div>	
</div>
	</div>

</section>

<section id="about_2" class="clearfix">
  <div class="container">
   <div class="row">
   <div class="container">
  <div class="row justify-content-md-center">
    <div class="col-md-auto" data-aos="fade-zoom-in" data-aos-duration="2000">
	<h1 className="text-dark h1"  style={{fontFamily:"auto"}}>Introduction</h1>
<p className="h3 text-center " style={{fontFamily:"auto", color:"#999"}}>At Chai Theory, we are on a mission to redefine the cafe experience by offering a unique blend of artisanal teas, gourmet food, and a warm, inviting ambiance. Our franchise opportunity is designed to welcome investors seeking a profitable, innovative, and customizable business venture within the food and beverage industry.
</p>
<br/>
    </div>
    {/* <div class="col-md-auto mt-3" style={{fontFamily:"auto"}}>
		<div className="h4 text-center" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom" 
	 data-aos-duration="2000">
		<span className="text-muted">
				Watsapp: </span>
				<span className="text-dark"> 
				<a href="tel: +91 6366333444">  6366333444</a></span>
		</div>
		<div className="h4 text-center" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"
	 data-aos-duration="2000">
		<span className="text-muted" style={{cursor:"pointer"}}>
		Instagram:</span>
		<span className="text-dark" style={{cursor:"pointer"}}>
		<a href="https://www.instagram.com/chaitheory/"> @chaithoery </a></span>
		</div>
		<div className="h4 text-center" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"
	 data-aos-duration="2000">
		<span className="text-muted">Email: </span>
		<span className="text-dark" style={{cursor:"pointer"}}>
		<a href="mailto:chaithoery.post@gmail.com"> chaithoery.post@gmail.com
		</a></span>
		</div> 	
    </div> */}

  </div>
  </div>
  
   <h2 className="text-center font-weight-bold">Why Invest in Chai Theory?</h2>
    <div class="about_2 clearfix">
     <div class="col-sm-4 about_2_left clearfix" data-aos="fade-right"   data-aos-duration="2000">
	  <div class="about_2_left_inner">
	  <h2>Diverse Investment Options:</h2>
	   <p>Chai Theory offers a range of investment options to suit various budgets, allowing investors to choose the model that aligns with their financial goals. We understand that flexibility is crucial, and we offer customizable outlet options to ensure your investment is tailored to your specific needs.</p>
	  
	  </div>
	  <div class="about_2_left_inner">
	   <h2>Unique Cafe Concept:</h2>
	   <p>Chai Theory stands out in the food and beverage industry with our unique concept of combining traditional and modern tea experiences, along with gourmet food offerings. This fusion offers a wide range of options, making it appealing to a diverse customer base.
</p>
</div>
	  <div class="about_2_left_inner">
	  <h2>Comprehensive Training and Support:</h2>
	   <p>Chai Theory is committed to the success of our franchise partners. We provide extensive training, ongoing support, and marketing resources to ensure your franchise thrives from day one.</p>
	  
	  </div>
	  <div class="about_2_left_inner">
	  <h2>Ethical Sourcing:</h2>
	   <p>We take pride in sourcing our teas and ingredients ethically, which not only enhances our product quality but also aligns with the values of socially responsible consumers.</p>
	  
	  </div>
	 </div>
	 <div class="col-sm-4 about_2_middle clearfix">
	  <p><img src={cup} style={{marginBottom:"41%", marginTop:"41%", height:"422px", marginLeft:"8%", marginRight:"8%"}} alt="cup" /></p>
	 </div>
	 <div class="col-sm-4 about_2_left clearfix" data-aos="fade-left"   data-aos-duration="2000">
   <div class="about_2_right_inner">
	  <h2>Recession-Resistant Business:</h2>
	   <p>Chai Theory has proven itself to be recession-resistant, as people continue to seek comfort and quality in food and beverage experiences. Our business model is designed to thrive in all economic climates.</p>
	  
	  </div>
	  <div class="about_2_right_inner">
	   <h2>Strong Brand Identity:</h2>
	   <p>Our brand is a symbol of quality, innovation, and authenticity. We have invested in building a recognizable and trustworthy name in the industry, which provides a competitive advantage to our franchisees.
</p>
	  </div>
	  <div class="about_2_right_inner">
	   <h2>Menu Variety:</h2>
	   <p>We offer an extensive menu, including a wide array of teas, coffees, and gourmet food items. This diversity appeals to a broad audience, ensuring repeat business and customer loyalty.
</p>
	  </div>
	  <div class="about_2_right_inner">
	   <h2>Innovation and Customization:</h2>
	   <p>Chai Theory encourages innovation, allowing franchisees to introduce local flavors and products, making each outlet a unique reflection of the community it serves.
</p>
	  </div>
	  <div class="about_2_right_inner">
	   <h2>Proven Track Record:</h2>
	   <p>With successful outlets and a growing customer base, Chai Theory has a proven track record of profitability. Investors can benefit from the experience and expertise of an established brand.
</p>
	  </div>
	 </div>
    </div>
   </div>
  </div>
  <br/>
  <Franchisee />
  {/* <FranchisePoints /> */}
{/* </section> */}


</section>

        </>
    )
}
export default FranchiseBusiness;