import React from 'react'
import brownie from "../assets/img/scoops/Brownie.jpg"
import choco from "../assets/img/scoops/choco.jpg"
import mango from "../assets/img/scoops/mango.jpg"
import strawberryIce from "../assets/img/scoops/strawberryIce.jpg"
import vanila from "../assets/img/scoops/vanila-scoops.jpg"

function Scoops() {
  return (
    <>

<section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={vanila} alt="img"/></div>
	  <div className="info">
      <h3>Regular:"₹ 35.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Vanilla</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={mango} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 39.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Mango</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={strawberryIce} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 35.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Strawberry</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={choco} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 39.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chocolate</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={brownie} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 89.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Brownie With Ice Cream</a></h4>
	 </div>
	</div>  
   </div>
  </div>
 </div>
</section>

    </>
  )
}

export default Scoops