import React from 'react'
import filter from "../assets/img/coffee/FilterCoffee.jpg"
import classic from "../assets/img/coffee/Classic.jpg"
import dark from "../assets/img/coffee/Dark-Chocolate-Coffee.jpg"
import hot from "../assets/img/coffee/Nutella-Hot-Coffee.jpg"
import frappe from "../assets/img/coffee/hazelnut-frappe.jpg"

function Coffee() {
  return (
    <>
      <section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12 w-100" >
    <div className="our_1">
	 {/* <h2 className="text-center"><span className="well_1"></span>Coffee</h2> */}
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}>
      <img src={filter} alt="img"/></div>
	  <div className="info">
		<h3>Small:"₹ 39.00"<br/>
        Medium: "₹ 65.00"<br/>
        Large: "₹ 79.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Filter Coffee</a></h4>
	 </div>
	</div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={classic} alt="img"/></div>
	  <div className="info">
    <h3>Small:"₹ 39.00"<br/>
        Medium: "₹ 65.00"<br/>
        Large: "₹ 79.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Classic </a></h4>
	 </div>
	</div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={dark} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 58.00"<br/>
        Medium: "₹ 85.00"<br/>
        Large: "₹ 99.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chocolate </a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={frappe} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 64.00"<br/>
        Medium: "₹ 95.00"<br/>
        Large: "₹ 109.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Hazelnut </a></h4>
	 </div>
	</div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={hot} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 59.00"<br/>
        Medium: "₹ 75.00"<br/>
        Large: "₹ 89.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Hot Chocolate</a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
    </>
  )
}

export default Coffee