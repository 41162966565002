import React from "react";
import milkshakes from "../assets/img/team/milkshake.jpg"
import bistea from "../assets/img/team/cup.jpg"
import snacks from "../assets/img/team/snacks.jpg"
// import ScrollTop from "./ScrollTop";
import ScrollToTop from "react-scroll-to-top";



function Blog() {
	return(
        <>
        <section id="center_5 w-100" className="clearfix">
 <div className="container">
  <div className="row">
   <div className="center_5 clearfix">
       <div className="center_5_top clearfix" >
	    <p><i className="fa fa-heart" style={{color:"#f79500"}}></i></p>
	    <h2>Always Tea-Trendy</h2>
		<h5>Read Tea News</h5>
	   </div> 
	   <div className="center_5_middle clearfix">
	    <div className="col-sm-4 center_5_middle_left clearfix">
		 <div className="center_5_middle_left_inner_1 clearfix">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={bistea} alt="tea"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		 <div className="center_5_middle_left_inner_2 claerfix">
		  <h4><a href="javascript:void(0)">Our special Biscuit cup theory</a></h4>
		  <h5>The idea behind the biscuit cup chai is that you can enjoy your tea and 
			then consume the cup as well, reducing waste and providing a convenient and 
			tasty treat. These edible cups are often flavored to complement the tea, and
			 they add a fun and novel twist to the traditional way of serving beverages.</h5>
		  <h6  className="mb-3"><a href="" style={{marginBottom:"1px"}}>by admin / Food</a></h6>
		  {/* <br/> */}
		 </div>
		 <div className="center_5_middle_left_inner_3 clearfix">
		  <p><a href="javascript:void(0)">June 27,2023</a></p>
		 </div>
		</div>
		<div className="col-sm-4 center_5_middle_left clearfix">
		 <div className="center_5_middle_left_inner_1 clearfix">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={milkshakes} alt="juice"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		 <div className="center_5_middle_left_inner_2 claerfix">
		  <h4><a href="javascript:void(0)">Jump For Juice</a></h4>
		  <h5>Juice is a flavorful and refreshing beverage that is derived from fruits 
			or vegetables. It's created by extracting the liquid content from these 
			natural sources, resulting in a drink that's packed with vitamins, minerals,
			 and sometimes fiber. Juice comes in various forms, including freshly
			  squeezed, bottled, or carton-packaged options.</h5>
		  <h6 className="mb-3"><a href="javascript:void(0)">by admin / Food</a></h6> 
		 </div>
		 <div className="center_5_middle_left_inner_3 clearfix">
		  <p><a href="javascript:void(0)">June 26,2023</a></p>
		 </div>
		</div>
		<div className="col-sm-4 center_5_middle_left clearfix">
		 <div className="center_5_middle_left_inner_1 clearfix">
		  <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={snacks} alt="snacks"/>
						<figcaption>
							<a href="javascript:void(0)">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
		 </div>
		 <div className="center_5_middle_left_inner_2 claerfix">
		  <h4><a href="javascript:void(0)">Dignissim Lacinia</a></h4>
		  <h5>A puff is a light, airy, and often flaky pastry that is beloved for its 
			delicate texture and versatility. Puffs are made by creating layers of dough and fat
			 through a process called lamination, resulting in a pastry that rises dramatically
			  when baked, creating a hollow interior.</h5>
		  <h6><a href="javascript:void(0)">by admin / Food</a></h6>
		 </div>
		 <div className="center_5_middle_left_inner_3 clearfix">
		  <p><a href="javascript:void(0)">June 25,2023</a></p>
		 </div>
		</div>
	   </div>
   </div>
  </div>
 </div>
 <ScrollToTop style={{backgroundColor:"#f79500"}} />
</section>
{/* <ScrollTop /> */}
        </>
    )
}
export default Blog;