import React from 'react'
import milkshakes from "../assets/img/shakes/milkshake.jpg"
import oreo from "../assets/img/shakes/Oreo-Milkshake.jpg"
import CH from "../assets/img/shakes/ChocolateHazelnut.jpg"
import ExMango from "../assets/img/shakes/Mangojuice.jpg"
import Sshakes from "../assets/img/shakes/Sshakes.jpg"
import kitkat from "../assets/img/shakes/big_kit_kat.jpg"
import chip from "../assets/img/shakes/choco-chips-thick-shake.png"
import CB from "../assets/img/shakes/CBrownie.jpg"
import vanila from "../assets/img/shakes/classic-vanilla.jpg"
import ChocoV from "../assets/img/shakes/chocoVanilla.jpg"

function Shakes() {
  return (
    <>
        <section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	 {/* <h2 className="text-center">
    <span className="well_1"></span>Shakes</h2> */}
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={milkshakes} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chocolate Oreo</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={ExMango} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Exotic Mango</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={Sshakes} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Strawberry</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={oreo} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Oreo</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={kitkat} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Kit Kat Blast</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={chip} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Choco Chip</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={CB} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Choco Brownie</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={vanila} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Vanilla</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={ChocoV} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Choco Vanilla</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={CH} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"<br/>
        Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chocolate Hazelnut</a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
    </>
  )
}

export default Shakes