import React from "react";
import "./PremiumLook/Menucart.css"
import cup from "../assets/img/cup.png"
import "../css/bootstrap.min.css"
import "../css/animate.css"

function Subscribe() {
    return(
        <>
<section id="center_2" className="clearfix w-95"  data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0">
	<div className="container">
  <div className="row" >
   <div className="center_2 clearfix">
   <div className="col-lg-5">
	 <div className="center_2_left_inner">
	  <p><img src={cup} width="100%"  alt="cup"/></p>
	 </div>
	</div>
	<div className="col-lg-7 mb-5 mb-lg-0 w-100" >
	 <div className="center_2_right_inner">
	  <h2 className="anim">Get Our Recipes Now!</h2>
	  <h5 className="anim">Chai Theory is Spreading Soon into your Neighbourhood</h5></div>
	  <div className="center_2_right_inner_middle clearfix">
	  <div className="col-sm-8 center_2_right_inner_1 clearfix w-100">
	  <input type="text" className="  search-query form-control form-control_new" placeholder="type your email"/>
	  <div className="col-sm-4 center_2_right_inner_2 clearfix w-100">
	  <p><a href="javascript:void(0)">subscribe</a></p>
	  </div>
	  </div>
	 </div>
	 <div className="center_2_right_bottom clearfix">
	  <ul>
	   <li><h4><a className="player_1" href="https://play.google.com"><i className="fa fa-play"></i>Play Store</a></h4></li>
	   <li><h4><a className="player_1" href="https://play.google.com">
	   <i className="fab fa-apple" style={{fontSize:"24px", color:"white"}}></i>
			App Store</a></h4></li>
	  </ul>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
 

        </>
    )
}
export default Subscribe;