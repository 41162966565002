import React from 'react'

function Franchisee() {
  return (
    <div>

<div class="container">
<h2 class="accordion-header-content-label text-center font-weight-bold">Unique Selling Points (USP)</h2>            
      
 <details class="accordion">
   <summary class="accordion-header">
     <div class="accordion-header-content p-3">
      <span class="accordion-header-content-title h3 font-weight-bold mb-4">&#x2022; Backed by Sristar Group</span>
     </div>
   </summary>     
     <div class="accordion-content">
       <p class="accordion-content-text h4 p-4 mx-auto">
       Chai Theory is proud to be backed by Sristar Group, a conglomerate with a proven track record in various industries. This backing ensures stability, support, and a wealth of experience to help franchisees succeed.
      </p>  
    </div>   
 </details>
 <details class="accordion">
   <summary class="accordion-header">
     <div class="accordion-header-content p-3">
      <span class="accordion-header-content-title h3 font-weight-bold mb-4">&#x2022; Mission to Create Successful Entrepreneurs:</span>
     </div>
   </summary>     
     <div class="accordion-content">
       <p class="accordion-content-text h4 p-4 mx-auto">
       Chai Theory is on a mission to create 100 successful entrepreneurs by 2024. This commitment to your success means you're not just joining a franchise; you're part of an ecosystem that is dedicated to making you thrive.
        </p>  
    </div>   
 </details>
 <details class="accordion">
   <summary class="accordion-header">
     <div class="accordion-header-content p-3">
      <span class="accordion-header-content-title h3 font-weight-bold mb-4">&#x2022; Ecosystem of Expertise:</span>
     </div>
   </summary>     
     <div class="accordion-content">
       <p class="accordion-content-text h4 p-4 mx-auto">
       Chai Theory's ecosystem includes Asli Kahani as the media wing, Digital Engine as digital transformation experts, and Sristar Consultancy for manpower solutions. This comprehensive support system covers everything from marketing to technology, ensuring your business operates at its best.
        </p>  
    </div>   
 </details>
 <details class="accordion">
   <summary class="accordion-header">
     <div class="accordion-header-content p-3">
      <span class="accordion-header-content-title h3 font-weight-bold mb-4">&#x2022; Young Energetic Brain with Diverse Experience:</span>
     </div>
   </summary>     
     <div class="accordion-content">
       <p class="accordion-content-text h4 p-4 mx-auto">
       The team behind Chai Theory comprises young, energetic professionals with over 15 years of experience in a range of industries. This diversity of expertise and energy guarantees innovative and adaptive strategies for success.
       </p>  
    </div>   
 </details>
</div>

    </div>
  )
}

export default Franchisee