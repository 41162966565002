import React from "react";  
import "../assets/css/style.css"
import milk from "../assets/img/Milk/plain-milk.jpg"
import black from "../assets/img/dark/blacktea.jpg"
import classic from "../assets/img/coffee/Classic.jpg"
import peach from "../assets/img/icedtheory/peach.jpg"
import mint from "../assets/img/Mojitos/mintmojito.jpg"
import mango from "../assets/img/scoops/mango.jpg"
import salt from '../assets/img/BakePoint/salt-cookies.jpg'
import hot from "../assets/img/coffee/Nutella-Hot-Coffee.jpg"
import Paneerroll from "../assets/img/Crunchies/Paneer-kathi-Roll-Featured-1.jpg"
import twister from "../assets/img/Crunchies/twister.jpg"
// import "../assets/bootstrap/css/bootstrap.min.css" 
// import "../assets/css/owl.carousel.css"
// import "../assets/css/magnific-popup.css"
// import "../assets/css/animate.css"
// import "../assets/css/meanmenu.min.css"
// import "../assets/css/main.css"
// import "../assets/css/responsive.css"

function MiniMenu() {
    return(
        <>
         <section id="menu" className="menu section-bg">
      <div className="container">

        <div className="menu-section">
          <h2>Menu</h2>
          <p>RAW MENU</p>
        </div>

        {/* <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="menu-flters">
              <li data-filter="*" className="filter-active">All</li>
              <li data-filter=".filter-starters">Starters</li>
              <li data-filter=".filter-salads">Salads</li>
              <li data-filter=".filter-specialty">Specialty</li>
            </ul>
          </div>
        </div> */}

        <div className="row menu-container">

          <div className="col-lg-6 menu-item filter-starters">
            <img src={milk} className="menu-img" alt="milk" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)">Milk-Theory</a><span>₹25 - ₹89</span>
            </div>
            <div className="menu-ingredients">
             Plain-milk, Desi, Ginger, Elaichi, Masala, Rose,
             Hazelnut, Ginger-Pepper, Ginger-Elaichi, Ginger-Masal
            </div>
          </div>

          <div className="col-lg-6 menu-item filter-specialty">
            <img src={black} className="menu-img" alt="black" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)">Dark-Theory</a><span>₹25 - ₹75</span>
            </div>
            <div className="menu-ingredients">
             Black, Lemon, Ginger-Lemon, Ginger-Tea
            </div>
          </div>

          <div className="col-lg-6 menu-item filter-starters">
            <img src={classic} className="menu-img" alt="classic" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)">Coffee</a><span>₹39 - ₹109</span>
            </div>
            <div className="menu-ingredients">
            Filter Coffee, Classic, Chocolate, Hazelnut, Hot Chocolate
			 </div>
          </div>

          <div className="col-lg-6 menu-item filter-salads">
            <img src={peach} className="menu-img" alt="peach" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)">Iced Theory</a><span>₹99 - ₹109</span>
            </div>
            <div className="menu-ingredients">
            Mint, Peach, Lemonade, Ginger Lemon, Hazelnut 
            </div>
          </div>

          <div className="col-lg-6 menu-item filter-specialty">
            <img src={mint} className="menu-img" alt="mint" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)">Mojitos</a><span>₹95 - ₹99</span>
            </div>
            <div className="menu-ingredients">
			     Mint, Lemonade, Strawberry, Green Apple, Blue Ice, SpicyMint & Lemon, Mango
			 </div>
          </div>

		  <div className="col-lg-6 menu-item filter-starters">
            <img src={mango} className="menu-img" alt="mango" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)">Ice Creams Scoops</a><span>₹35 to ₹89</span>
            </div>
            <div className="menu-ingredients">
            Vanilla, Mango, Strawberry, Brownie With Ice Cream, Chocolate
            </div>
          </div>

		  <div className="col-lg-6 menu-item filter-starters">
            <img src={salt} className="menu-img" alt="salt" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)">Bake Point</a><span>₹6 to ₹65</span>
            </div>
            <div className="menu-ingredients">
            Salt Biscuit, Sweet Biscuit, Banana Cake, Chocolate Donut,
            Brownie, Chocochip Muffin
            </div>
          </div>

		  <div className="col-lg-6 menu-item filter-starters">
            <img src={hot} className="menu-img" alt="hot" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)">Cold Coffee</a><span>₹79 to ₹89</span>
            </div>
            <div className="menu-ingredients">
            Classic, Hazelnut, Mango, Chocolate 
            </div>
          </div>

          <div className="col-lg-6 menu-item filter-specialty">
            <img src={Paneerroll} className="menu-img" alt="Paneerroll" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)"> Rolls</a><span>₹79 to ₹109</span>
            </div>
            <div className="menu-ingredients">
			Veg Roll, Egg Roll, Paneer Roll, Chicken Roll
			 </div>
          </div>

          <div className="col-lg-6 menu-item filter-specialty">
            <img src={twister} className="menu-img" alt="twister" data-aos="zoom-in"/>
            <div className="menu-content">
              <a href="javascript:void(0)"> Wraps</a><span>₹109 to ₹149</span>
            </div>
            <div className="menu-ingredients">
            Veg Wrap, Egg Wrap, Paneer Wrap, Chicken Wrap
			 </div>
          </div>

        </div>

      </div>
    </section>
        </>
    )
}
export default MiniMenu;