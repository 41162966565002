import React from "react";

function ApplyNow(){
    return(
        <>
        <div id="survey-container">

<h1 id="title">Job form</h1>

<p id="description">Fill in to apply</p>

<form id="survey-form">

  
  <label id="name-label" className="row-label" for="name">Name:</label>
  <input id="name" className="row-input" type="text"
   placeholder="Enter your name" required/>

  
  <label id="email-label" className="row-label" for="email">Email:</label>
  <input id="email" className="row-input" type="email"
   placeholder="Enter your email" required/>

 
  <label className="row-label" for="dropdown">Level of education:</label>
  <select id="dropdown" className="row-input" required>
    <option disabled selected>Select an option</option>
    <option value="primary">Primary education</option>
    <option value="secondary">Secondary education</option>
    <option value="higher">Higher education</option>
    <option value="na">No answer</option>
  </select>

  <label className="row-label" for="dropdown">How did you find out about us?</label>
  <select id="dropdown" className="row-input" required>
    <option disabled selected>Select an option</option>
    <option value="primary">Facebook</option>
    <option value="secondary">Linkedin</option>
    <option value="higher">Indeed</option>
    <option value="na">Instagram</option>
  </select>

  <label className="row-label" for="dropdown">Which option best describes your current role?</label>
  <select id="dropdown" className="row-input" required style={{width:"fit-content"}}>
    <option disabled selected>Select an option</option>
    <option value="primary">Full Time Job</option>
    <option value="secondary">Part Time Job</option>
    <option value="higher">Intern</option>
    <option value="na">Other</option>
  </select>

 
  <label id="number-label" className="row-label" for="number">Years of experience (optional):</label>
  <input id="number" className="row-input" type="number" placeholder="Enter number of years of experience" min="0" max="50"/>

 
  <p className="row-label">What are you interested in?</p>

  <label className="row-input small" for="box-windows" style={{width:"fit-content"}}>
    <input type="checkbox" id="box-windows" name="checkbox" value="telecalling"/>
    <span className="inline-label">Tele Calling</span>
  </label>

  <label className="row-input small" for="box-word" style={{width:"fit-content"}}>
    <input type="checkbox" id="box-word" name="checkbox" value="salesexecutive"/>
    <span className="inline-label">Sales Executive</span>
  </label>

  <label className="row-input small" for="box-excel" style={{width:"fit-content"}}>
    <input type="checkbox" id="box-excel" name="checkbox" value="fullstackdeveloper"/>
    <span className="inline-label">Full Stack Developer</span>
  </label>

  <label className="row-input small" for="box-powerpoint" style={{width:"fit-content"}}>
    <input type="checkbox" id="box-powerpoint" name="checkbox" value="graphicdesigner"/>
    <span className="inline-label">Graphic Designer</span>
  </label>

  <label className="row-input small" for="box-outlook" style={{width:"fit-content"}}>
    <input type="checkbox" id="box-outlook" name="checkbox" value="other"/>
    <span className="inline-label">Other</span>
  </label>

  <p className="row-label">Expected salary:</p>

  <label className="row-input small" for="below-3k">
    <input type="radio" id="below-3k" name="radio" value="below-3k" style={{width:"fit-content"}}/>
    <span className="inline-label">Below ₹15K</span>
  </label>

  <label className="row-input small" for="3k-4k">
    <input type="radio" id="3k-4k" name="radio" value="3k-4k" style={{width:"fit-content"}}/>
    <span className="inline-label">₹15K - ₹20K</span>
  </label>

  <label className="row-input small" for="4k-5k" style={{width:"fit-content"}}>
    <input type="radio" id="4k-5k" name="radio" value="4k-5k"/>
    <span className="inline-label">₹20K - ₹25K</span>
  </label>

  <label className="row-input small" for="above-5k" style={{width:"fit-content"}}>
    <input type="radio" id="above-5k" name="radio" value="above-5k"/>
    <span className="inline-label">Above ₹25K</span>
  </label>

  <label className="row-input small" for="dont-know" style={{width:"fit-content"}}>
    <input type="radio" id="dont-know" name="radio" value="dont know"/>
    <span className="inline-label">Don't know</span>
  </label>

  <label className="row-label" for="comments">Additional informations:</label>
  <textarea id="comments" placeholder="Enter other informations here..."></textarea>

  <div>
  <label id="file" className="row-label" for="file">Upload Your CV:</label>
  <input id="file" className="  " type="file" name="file" required/>
{/* <label for="file">Upload Your CV:
  </label>
<input type="file" name="file"/> */}
  </div>
  <br/>
  
  <button type="submit" className="button_1" style={{backgroundColor:"#f79500"}}>Submit</button>

</form>

</div>


        </>
    )
}
export default ApplyNow