import React from 'react'
import samosa from "../assets/img/munchies/samosa.jpg"
import alu from "../assets/img/munchies/aloo.jpg"
import bun from "../assets/img/munchies/bun-muska.jpg"
import jam from "../assets/img/munchies/Bun-Jam.jpg"
import bunchoco from "../assets/img/munchies/Bun-Choco.jpg"
import vadapav from "../assets/img/munchies/Vada-Pav.jpg"
import vegpuff from "../assets/img/munchies/veg-puff.jpg"
import eggpuff from "../assets/img/munchies/egg-puffs.jpg"
import paneerpuff from "../assets/img/munchies/Spinach-Paneer-Puffs-28.jpg"
import chknpuff from "../assets/img/munchies/Chicken-Puff.jpg"
import cornpuff from "../assets/img/munchies/corn-puff.jpg"
import pizzapuff from "../assets/img/munchies/Pizza-Puff.jpg"
import puff from "../assets/img/munchies/MaggiPuff.jpg"

function Munchies() {
  return (
    <>

<section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={samosa} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 25.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Samosa</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={alu} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 28.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Aloo Bun</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={bun} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 49.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Bun Maska</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={jam} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 59.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Bun Maska Jam</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={bunchoco} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 69.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Bun Maska Choco</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={vadapav} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 45.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Vada Pav</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={vegpuff} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 30.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veg Puff</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={eggpuff} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 42.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Egg Puff</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={paneerpuff} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 50.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Paneer Puff</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={chknpuff} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 60.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Puff</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={cornpuff} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 60.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Corn Puff</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={pizzapuff} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 60.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Pizza Puff</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={puff} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 60.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Maggi Puff</a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>

    </>
  )
}

export default Munchies