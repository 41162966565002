import React from "react";
// import MenuList from "../Menus/MenuList";

function Menu() {
    return(
        <>
        {/* <MenuList /> */}
        </>
    )
}
export default Menu;