import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useRef, useState } from 'react';
// import './styles.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

export default function Review() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><div className="single-testimonial-slider">							
							<div className="client-meta">
								<h3>Richa Agarwal<span>Chai Lover</span></h3>
								<p className="testimonial-body" style={{width:"75%"}}>
									"I absolutely love visiting the chai Theory! The aroma of the freshly brewed chai hits you as soon as you walk in, 
									creating an instant sense of warmth and comfort. The staff is incredibly friendly and knowledgeable, always ready to recommend 
									the perfect chai blend based on your preferences. The cozy and inviting ambiance makes it the ideal place to relax, catch up with friends, 
									or simply enjoy a peaceful moment alone. The variety of chai flavors and the option to customize your drink to your liking are fantastic. 
									Every sip is a delight, and I can't get enough of their delicious chai. It's my go-to spot for a satisfying and rejuvenating chai experience!"
								</p>
								<div className="last-icon">
									<i className="fas fa-quote-right"></i>
								</div>               
							</div>
						</div></SwiperSlide>
        <SwiperSlide><div className="single-testimonial-slider">							
							<div className="client-meta">
								<h3>Rachana Agarwal<span>Chai Lover</span></h3>
								<p className="testimonial-body">
								ChaiCharm's spiced chai is a comforting embrace in a cup. The blend of cinnamon, 
								cardamom, and ginger is perfectly balanced, creating a harmonious dance of flavors. Served piping hot with a hint of sweetness, it's a delightful pick-me-up on a chilly afternoon.
								</p>
								<div className="last-icon">
									<i className="fas fa-quote-right"></i>
								</div>               
							</div>
						</div></SwiperSlide>
        <SwiperSlide><div className="single-testimonial-slider">							
							<div className="client-meta">
								<h3>Dee Jathan<span>Chai Lover</span></h3>
								<p className="testimonial-body">
									" The "Masala Magic" chai at SpiceTrail Teas is an enchanting journey to India's streets."
								</p>
								<div className="last-icon">
									<i className="fas fa-quote-right"></i>
								</div>               
							</div>
						</div></SwiperSlide>
        <SwiperSlide><div className="single-testimonial-slider">							
							<div className="client-meta">
								<h3>Nicole<span>Chai Lover</span></h3>
								<p className="testimonial-body">
								"Relaxing at Cafe Haven is like finding solace in a cozy corner, with its aromatic brews and serene ambiance."
								</p>
								<div className="last-icon">
									<i className="fas fa-quote-right"></i>
								</div>               
							</div>
						</div></SwiperSlide>
       
      </Swiper>
    </>
  );
}
