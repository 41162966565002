import React from "react";
import AboutPage from "../AboutPages/Aboutpage";
// import Footer from "../Footer/Footer";
import HomeFooter from "../Components/HomeFooter";
// import Image from "../example/Image";
// import Solve from "../example/Solve";
// import MenuCart from "../PremiumLook/Menucart";

function About() {
return(
    <>
     <AboutPage />
     <HomeFooter />
     {/* <Solve /> */}
    {/* <Footer />     */}
     {/* <MenuCart /> */}
    </>
)
}
export default About;