import React, { useEffect } from 'react'
import gp from "../assets/img/Milk/GingerPepper.jpg"
import hm from "../assets/img/Milk/HazelnutMilk.jpeg"
import ge from "../assets/img/Milk/elaichi.jpeg"
import gmilk from "../assets/img/Milk/ginger.jpg"
import masala from "../assets/img/Milk/masala.jpg"
import milk from "../assets/img/Milk/plain-milk.jpg"
import rose from "../assets/img/Milk/Rose.jpg"
import desi from "../assets/img/Milk/desi.jpeg"
import Aos from "aos";
import "./special.css"

function SpecialTheory() {
	useEffect(() =>{
		Aos.init();
	}, [])
  return (
    <>
        <section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12">
    <div className="our_1" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="500">
	 {/* <h2 className="text-center"><span className="well_1"></span> Milk Theory</h2> */}
	<br/>
    </div>
	<div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={milk} alt="img"/></div>
	  <div className="info">
		<h3>Small:"₹ 25.00"<br/>
        Medium: "₹ 39.00"<br/>
        Large: "₹ 55.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Plain Milk</a></h4>
	 </div>
	</div>
	<div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={desi} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 34.00"<br/>
        Medium: "₹ 49.00"<br/>
        Large: "₹ 64.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Desi</a></h4>
	 </div>
	</div>
	<div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={gmilk} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 39.00"<br/>
        Medium: "₹ 55.00"<br/>
        Large: "₹ 69.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Ginger</a></h4>
	 </div>
	</div>
    <div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={ge} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 49.00"<br/>
        Medium: "₹ 65.00"<br/>
        Large: "₹ 79.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Elaichi</a></h4>
	 </div>
	</div>
	<div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={masala} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 49.00"<br/>
        Medium: "₹ 65.00"<br/>
        Large: "₹ 79.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Masala</a></h4>
	 </div>
	</div>
    <div className="col-sm-3"
	data-aos="zoom-in"
	data-aos-easing="ease-out-cubic"
	data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={rose} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 59.00"<br/>
        Medium: "₹ 75.00"<br/>
        Large: "₹ 89.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Rose</a></h4>
	 </div>
	</div>
    <div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={hm} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 59.00"<br/>
        Medium: "₹ 75.00"<br/>
        Large: "₹ 89.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Hazelnut</a></h4>
	 </div>
	</div>
    <div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={gp} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 59.00"<br/>
        Medium: "₹ 75.00"<br/>
        Large: "₹ 89.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Ginger Pepper</a></h4>
	 </div>
	</div>
    <div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={ge} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 59.00"<br/>
        Medium: "₹ 75.00"<br/>
        Large: "₹ 89.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Ginger Elaichi</a></h4>
	 </div>
	</div>
    <div className="col-sm-3" data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={gmilk} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 59.00"<br/>
        Medium: "₹ 75.00"<br/>
        Large: "₹ 89.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Ginger Masala</a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
    </>
  )
}
export default SpecialTheory