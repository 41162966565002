import React from "react";
import HomeFooter from "../Components/HomeFooter";

function Careers() {
    return(
        <>
            <section id="career" className="text-center abt">
         <p className="first" style={{color:"white", fontSize:"29px"}}>Our Mission</p>
      <div className="container ">
        <div className="row">
        <div className="leading animate fadeInDown one">
        <p className="lead">        
        Our core mission centers on empowering a new generation of dynamic, enthusiastic entrepreneurs who are driven by a burning desire to accomplish remarkable feats with limited or even no initial capital investment. Our commitment lies in nurturing the spirit of entrepreneurship, acting as a thriving hub for networking and collaboration, delivering expert consultancy services, and facilitating startup growth through franchising opportunities. At the heart of our endeavor is the aim to equip and embolden individuals, igniting their creative sparks and propelling them towards success.
<br/><br/>
We are dedicated to providing essential support and resources to aspiring entrepreneurs, irrespective of their financial constraints. Our primary objective is to revitalize their entrepreneurial journeys, connecting them with a dynamic community that encourages innovative thinking and fosters growth. Through our comprehensive services and franchising options, we endeavor to enable individuals to unleash their potential and achieve significant milestones, ultimately propelling them towards their goals and dreams.
<br/><br/>
Chai theory also offers the opportunity to become a part of the team and contribute to the franchise's growth. You can connect with us on:
 </p></div>
<div className="col-lg-6 offset-lg-3 text-center">            
<a href="/apply" className="cart-btn btn-lg third" style={{marginBottom:"10px",borderRadius: "25% 10%",justifyContent:"space-between"}}> 
  Apply  <br/>For Candidate</a>
<a href="/applyhere" className="cart-btn btn-lg third" style={{marginBottom:"10px", marginLeft:"10px",borderRadius: "10% 25%"}}>
  Apply  <br/>For Employer</a></div>
<br/>
<br/>
          <div className="col-lg-4 col-sm-6 col-ex-12 about-item wow lightSpeedIn" data-wow-offset="200" >
            <span className="fas fa-handshake"></span>
            <h4>Business</h4>
             </div>
          <div className="col-lg-4 col-sm-6 col-ex-12 about-item wow lightSpeedIn" data-wow-offset="200">
            <span className="fas fa-headset"></span>
            <h4>Customer care</h4>
            </div>
         
          <div className="col-lg-4 col-sm-6 col-ex-12 about-item wow lightSpeedIn" data-wow-offset="200">
            <span className="fa fa-desktop"></span>
            <h4>Technology</h4>
            </div>
          
        </div>  
        
      </div>
    </section>
    <HomeFooter />
        </>
    )
}
export default Careers;