import React from 'react'
// import "./Click"
import "./Album.css"
import g1 from "../assets/img/Gallery/12.jpg"
import g2 from "../assets/img/Gallery/2.jpg"
import g3 from "../assets/img/Gallery/Cheese Jalapeno.jpg"
import g4 from "../assets/img/Gallery/Chocolate Hazelnut.jpg"
import g5 from "../assets/img/Gallery/Classic Fries_1.jpg"
import g6 from "../assets/img/Gallery/butter garlic.jpg"
import g7 from "../assets/img/Gallery/choco chip.jpg"
import g8 from "../assets/img/Gallery/choco vanilla.jpg"
import g9 from "../assets/img/Gallery/chocolate oreo.jpg"
import g10 from "../assets/img/Gallery/classic fries.jpg"
// import Gallery from './Gallery'
import ModalImage from "react-modal-image";




function Album() {

  return (
    <>
    {/* <Gallery /> */}
      <h1 style={{ color: "#f79500" }}>Gallery</h1><hr />
      <div id="gallery" className="container-fluid" >
      <ModalImage
       small={g1}
       medium={g1} className="responsive"/>
          {/* <img src={g1} className="img-responsive" alt='gallery' /> */}

          <ModalImage
       small={g2}
       medium={g2} className="responsive"/>
        {/* <a href={g2}><img src={g2} className="img-responsive" alt='gallery' /></a> */}

<ModalImage
       small={g3}
       medium={g3} className="responsive"/>
        {/* <a href={g3}>  <img src={g3} className="img-responsive" alt='gallery' /></a> */}
      
        {/* <iframe src="https://www.youtube.com/embed/wTcQifp8XYw" title="video"
          // frameborder="0" allowfullscreen
           height="100%" width="100%" alt='video'></iframe> */}
          
        
        <ModalImage
       small={g4}
       medium={g4} className="responsive"/>
        {/* <a href={g4} ><img src={g4} className="img-responsive" alt='gallery' /></a> */}

        {/* <iframe src="https://www.youtube.com/embed/wTcQifp8XYw" title="video"
          // frameborder="0" allowfullscreen
           height="100%" width="100%" alt='video'></iframe> */}
        {/* <video controls autostart autoPlay src="https://www.youtube.com/watch?v=wTcQifp8XYw" type="video/mp4" /> */}
       
        <ModalImage
       small={g5}
       medium={g5} className="responsive"/>
        {/* <a href={g5} ><img src={g5} className="img-responsive" alt='gallery' /></a> */}

        <ModalImage
       small={g6}
       medium={g6} className="responsive"/>
        {/* <a href={g6} ><img src={g6} className="card img-responsive" alt='gallery' /></a> */}


<ModalImage
       small={g5}
       medium={g5} className="responsive"/>
        {/* <a href={g5} ><img src={g5} className="img-responsive" alt='gallery' /></a> */}


        <ModalImage
       small={g6}
       medium={g6} className="responsive"/>
      {/* <a href={g6} ><img src={g6} className="img-responsive" alt='gallery' /></a> */}



        <ModalImage
       small={g7}
       medium={g7} className="responsive"/>
        {/* <a href={g7} ><img src={g7} className="img-responsive" alt='gallery' /></a> */}
        
        <ModalImage
       small={g8}
       medium={g8} className="responsive"/>
        {/* <a href={g8} ><img src={g8} className="img-responsive" alt='gallery' /></a> */}
       
        <ModalImage
       small={g10}
       medium={g10} className="responsive"/>
        {/* <a href={g6} ><img src={g6} className="img-responsive" alt='gallery' /></a> */}
       
        <ModalImage
       small={g9}
       medium={g9} className="responsive"/>
        {/* <a href={g6} ><img src={g6} className="img-responsive" alt='gallery' /></a> */}
        {/* <a href='' ><img src="https://source.unsplash.com/768x992?female,portrait" className="img-responsive" alt='gallery'/></a>  */}

      </div>
     


    </>
  )
}

export default Album