import React from 'react'
import alkaline from "../assets/img/immunity/EvocusBlackAlkaline.jpg"
import water from "../assets/img/immunity/Mineral-Enriched-Clear-Alkaline-Water-Evocus-750ml2.jpg"

function Immunity() {
  return (
    <> 
     <section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12  d-flex justify-content-center" data-aos="fade-up"
     data-aos-easing="linear"
     data-aos-duration="500" >
    <div className="our_1">
	 {/* <h2 className="text-center"><span className="well_1"></span>Immunity Booster</h2> */}
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}>
      <img src={alkaline} alt="img"/></div>
	  <div className="info">
		<h3>250 ML:"₹ 60.00"<br/>
        500 ML: "₹ 100.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Evocus Black Alkaline</a></h4>
	 </div>
	</div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}>
        <img src={water} alt="img"/></div>
	  <div className="info">
      <h3>500 ML: "₹ 80.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Evocus Clear Alkaline </a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
    </>
  )
}

export default Immunity