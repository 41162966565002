import React from "react";
// import Footer from "../Footer/Footer";
import Contactus from "../ContactPage/Contactus";
import HomeFooter from "../Components/HomeFooter";


function Conatct() {
    return(
        <>
        <Contactus />
	{/* <Footer /> */}
    <HomeFooter />
        </>
    )
}
export default Conatct;