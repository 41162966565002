import React from 'react'
import black from "../assets/img/dark/blacktea.jpg"
import lmn from "../assets/img/dark/Lemon.jpg"
import greentea from "../assets/img/dark/GreenTea.jpg"
import glt from "../assets/img/dark/Ginger_lemon_tea_recipe.jpg"

function DarkTheory() {
  return (
    <>
      <section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12">
    <div className="our_1" data-aos="fade-up"
     data-aos-easing="linear"
     data-aos-duration="500">
	 {/* <h2 className="text-center">
    <span className="well_1"></span>Dark Theory </h2> */}
	<br/>
    </div>
	<div className="col-sm-3" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="500">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={black} alt="img"/></div>
	  <div className="info">
		<h3>Small:"₹ 25.00"<br/>
        Medium: "₹ 35.00"<br/>
        Large: "₹ 49.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Black</a></h4>
	 </div>
	</div>
	<div className="col-sm-3"data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="500">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={lmn} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 35.00"<br/>
        Medium: "₹ 50.00"<br/>
        Large: "₹ 65.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Lemon</a></h4>
	 </div>
	</div>
	<div className="col-sm-3" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="500">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={glt} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 45.00"<br/>
        Medium: "₹ 59.00"<br/>
        Large: "₹ 75.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Ginger Lemon </a></h4>
	 </div>
	</div>
    <div className="col-sm-3" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="500">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={greentea} alt="img"/></div>
	  <div className="info">
      <h3>Small:"₹ 49.00"<br/>
        Medium: "₹ 65.00"<br/>
        Large: "₹ 69.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Green Tea </a></h4>
	 </div>
	</div>
   </div>
  </div>
 </div>
</section>
    </>
  )
}

export default DarkTheory