import React from 'react'
import omlet from "../assets/img/img/omlet.jpg"
import omlett from "../assets/img/img/omeltte.jpg"
import cornOmlet from "../assets/img/img/corn.jpg"
import regular from "../assets/img/img/regular.jpg"
import VCO from "../assets/img/img/vegCheeseOmlet.jpg"

function AndeKaFunda() {
  return (
    <>

<section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={regular} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 59.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Regular Omlet</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={omlet} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 69.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Tandoori Omlet</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={omlett} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 79.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Cheese Omlet</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={cornOmlet} alt="img"/></div>
	  <div className="info">
    <h3>Regular: "₹ 89.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Corn Omlet</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={VCO} alt="img"/></div>
	  <div className="info">
    <h3>Regular: "₹ 99.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veggie Cheese Omlet</a></h4>
	 </div>
	</div>
    </div>
  </div>
 </div>
</section>

    </>
  )
}

export default AndeKaFunda