import React from 'react'
import chknwrap from "../assets/img/Wraps/chknwrap.jpg"
import vegwrap from "../assets/img/Wraps/vegwrap.jpg"
import paneerwrap from "../assets/img/Wraps/paneerwrap.jpg"
import eggwrap from "../assets/img/Wraps/eggwrap.jpg"

function Wraps() {
  return (
    <>
    
    <section id="our">
 <div className="container">
  <div className="row">
   <div className="col-sm-12" data-aos="fade-right"
     data-aos-easing="linear"
     data-aos-duration="1500">
    <div className="our_1">
	<br/>
    </div>
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={vegwrap} alt="img"/></div>
	  <div className="info">
		<h3>Regular:"₹ 199.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Veg Wrap</a></h4>
	 </div>
	</div>
	
	<div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={eggwrap} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 119.00"</h3>
	  </div></a></div>
	 </div>
	 <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Egg Wrap</a></h4>
	 </div>
	</div>
    <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={paneerwrap} alt="img"/></div>
	  <div className="info">
    <h3>Regular:"₹ 129.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Paneer Wrap</a></h4>
	 </div>
	</div>
  <div className="col-sm-3">
	 <div className="our_2">
	  <div className="ih-item square effect13 left_to_right"><a href='javascript:void(0)'>
	  <div className="img" style={{height:"280px", width:"100%"}}><img src={chknwrap} alt="img"/></div>
	  <div className="info">
    <h3>Loaded: "₹ 149.00"</h3>
	  </div></a></div>
	 </div>
   <div className="our_3">
	  <h4 className="text-center"><a href='javascript:void(0)'>Chicken Wrap</a></h4>
	 </div>
	</div>
    </div>
  </div>
 </div>
</section>
    
    </>
  )
}

export default Wraps