import React from "react";
import fries from "../assets/img/Crunchies/fries.jpg"
import perifries from "../assets/img/Crunchies/PeriPeriTwister.jpg"
import peritwister from "../assets/img/Crunchies/PeriPeriTwister.jpg"
import babycorn from "../assets/img/Crunchies/babycorn.jpg"
import fried from "../assets/img/Crunchies/fried-chicken-tenders.jpg"
import cheesefried from "../assets/img/Crunchies/eating-vegan-chili-cheese-fries.jpg"

function HomeFooter() {
    return(
        <>
      <section id="footer" className="clearfix">
 <div className="container">
  <div className="row">
   <div className="footer w-100" style={{textAlign:"center"}}>
    <div className="col-sm-7 footer_left ">
	 <div className="footer_left_inner w-100">
	  <h2>Dont be shy, say HI!</h2>
	  <h5><a href="tel:"><i className="fa-solid fa-phone"></i>
	  +91 6366333444
</a></h5>
	  <h5><a href="javascript:void(0)"><i className="fa fa-clock-o"></i>
	  Mon ╸ Sun 7:00AM - 1:00AM<br/>
	  {/* <span style={{marginRight:"25px"}}></span>Sat ╸ Sun 24Hours */}
	  </a>
	  </h5>
	  <h5><a href="https://goo.gl/maps/KPG8kc5CYX4dYLiV6"><i className="fa fa-link"></i>Site no-104, opposite Mayuri, Hotel,<br/> New BEL Rd, Chikkamaranahalli, AG's Layout,<br/> Bengaluru, Karnataka 560054</a></h5>
	 
	 </div>
	</div>
	<div className="col-sm-3 footer_left">
					<div className="social-icons">
						 <h2>Follow Us</h2>
						<ul>
							<li><a href="https://www.facebook.com/chaitheory" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
							<li><a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fchaitheory" target="_blank"><i className="fab fa-twitter"></i></a></li>
							<li><a href="https://www.instagram.com/chaitheory/" target="_blank"><i className="fab fa-instagram" style={{fontSize:"20px"}}></i></a></li><br/>
							<li><a href="https://www.youtube.com/@sristargoldcompany" target="_blank"><i className="fab fa-youtube"></i></a></li>
							<li><a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHrIjwoKbeHsAAAAYlXzaAoJCFQODez_V8UUdh5b-NikCZm6y3OlF9p_IO6exI8JI6mvnJqNQuAJCvxvOc3sx9s1e4oWH9NAwdnrBL1TyavN6W9AKQmV4OuDQDQOUIAiPPbFTE=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fchaitheory" target="_blank">
								<i className="fab fa-linkedin"></i></a></li>
						</ul>
					</div>
				</div>
	{/* <div className="col-sm-4 footer_middle text-center">
	 <div className="footer_middle_inner">
	  <h2>Follow Us</h2>
	  <p><a href="javascript:void(0)">Monday…………………………..4:00AM - 1:00AM</a></p>
	   <p><a href="javascript:void(0)">Tuesday…………………………..4:00AM - 1:00AM</a></p>
	    <p><a href="javascript:void(0)">Wednesday……………………..4:00AM - 1:00AM</a></p>
		 <p><a href="javascript:void(0)">Thursday…………………………4:00AM - 1:00AM</a></p>
		  <p><a href="javascript:void(0)">Friday……………………………..4:00AM - 1:00AM</a></p>
		   <p><a href="javascript:void(0)">Saturday………………………...24 Hours</a></p>
		    <p><a href="javascript:void(0)">Sunday……………………………24 Hours</a></p>
	 </div>
	</div> */}
	{/* <div className="col-sm-4 footer_right clearfix text-center">
	  <div className="footer_right_top clearfix">
	    <h2>Instagram</h2>
	  </div>
	 <div className="footer_right_inner clearfix">
	  <div className="col-sm-4 footer_right_inner_left clearfix">
	   <div className="footer_right_inner_left_bottom clearfix">
	    <div className="grid clearfix">
					<figure className="effect-jazz">	
						<img src={fries} alt="img25"/>
						<figcaption>
							<a href="https://www.instagram.com/chaitheory/" >View more</a>						</figcaption>			
		  </figure>
					
	  </div>
	   </div>
	  </div>
	  <div className="col-sm-4 footer_right_inner_left clearfix">
	   <div className="footer_right_inner_left_bottom clearfix">
	    <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={perifries} alt="img25"/>
						<figcaption>
							<a href="https://www.instagram.com/chaitheory/">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
	   </div>
	  </div>
	  <div className="col-sm-4 footer_right_inner_left clearfix">
	   <div className="footer_right_inner_left_bottom clearfix">
	    <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={peritwister} alt="img25"/>
						<figcaption>
							<a href="https://www.instagram.com/chaitheory/">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
	   </div>
	  </div>
	 </div>
	 <div className="footer_right_inner clearfix">
	  <div className="col-sm-4 footer_right_inner_left clearfix">
	   <div className="footer_right_inner_left_bottom clearfix">
	    <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={babycorn} alt="img25"/>
						<figcaption>
							<a href="https://www.instagram.com/chaitheory/">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
	   </div>
	  </div>
	  <div className="col-sm-4 footer_right_inner_left clearfix">
	   <div className="footer_right_inner_left_bottom clearfix">
	    <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={fried} alt="img25"/>
						<figcaption>
							<a href="https://www.instagram.com/chaitheory/">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
	   </div>
	  </div>
	  <div className="col-sm-4 footer_right_inner_left clearfix">
	   <div className="footer_right_inner_left_bottom clearfix">
	    <div className="grid clearfix">
					<figure className="effect-jazz">
						<img src={cheesefried} alt="img25"/>
						<figcaption>
							<a href="https://www.instagram.com/chaitheory/">View more</a>						</figcaption>			
		  </figure>
					
	  </div>
	   </div>
	  </div>
	 </div>
	</div> */}
   </div>
  </div>
 </div>
</section>
<div className="copyright">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 text-right col-md-12">
					<p>Copyright &copy; SriStar Group 2023 , 
						All Rights Reserved.<br/>
					</p>
				</div>
				{/* <div className="col-lg-6 text-right col-md-12 color-white">
					<div className="social-icons">
						<ul>
							<li><a href="https://www.facebook.com/chaitheory" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
							<li><a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fchaitheory" target="_blank"><i className="fab fa-twitter"></i></a></li>
							<li><a href="https://www.instagram.com/chaitheory/" target="_blank"><i className="fab fa-instagram" style={{fontSize:"20px"}}></i></a></li>
							<li><a href="https://www.youtube.com/@sristargoldcompany" target="_blank"><i className="fab fa-youtube"></i></a></li>
							<li><a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHrIjwoKbeHsAAAAYlXzaAoJCFQODez_V8UUdh5b-NikCZm6y3OlF9p_IO6exI8JI6mvnJqNQuAJCvxvOc3sx9s1e4oWH9NAwdnrBL1TyavN6W9AKQmV4OuDQDQOUIAiPPbFTE=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fchaitheory" target="_blank">
								<i className="fab fa-linkedin"></i></a></li>
						</ul>
					</div>
				</div> */}
			</div>
		</div>
	</div>
        </>
    )
}
export default HomeFooter