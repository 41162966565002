import React from "react";
import Homepage from "../Components/Homepage";

function Home() {
    return(
        <>
        <Homepage />
        </>
    )
}
export default Home;